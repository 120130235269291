export default function useFieldFilterControls(columnName, filterObject) {
    const toggle = () => {
        const item = filterObject.getItem(columnName);
        item.excluded = !item.excluded;
        doFilter();
    }

    const reset = () => {
        const item = filterObject.getItem(columnName);
        item.excluded = false;
        item.resetItem();
        doFilter();
    }
        
    const remove = () => {
        filterObject.removeItem(columnName);
        doFilter();
    }

    const applySearchEnter = () => {
        doFilter();
    }

    const applyDateBetween = () => {
        const item = filterObject.getItem(columnName);
        item.selectedValue = [item.combinedStartValue, item.combinedEndValue];
        doFilter();
    }

    const applyBit = (evt) => {
        const item = filterObject.getItem(columnName);
        item.selectedValue = evt.target.value;
        doFilter();
    }

    const doFilter = () => {
       filterObject.apply();
    }
    
    const getFiltersList = (context:any) =>{
        return filterObject.filtersListDO.data.filter((item:any)=>{
            if(item.Mine) return true;
            if(item.SharedPerson) return true;
            if(item.AccessIdPath  == null && item.OrgUnit_ID == context.id) return true;
            if(context.idPath.startsWith(item.AccessIdPath)) return true;

            return false;
        });
    }

    return { toggle, reset, remove, applySearchEnter, applyDateBetween, applyBit, doFilter, getFiltersList };
}
